import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import { defineComponent } from "vue";
import authStore from "@/store/auth";
import authHttp from "@/http/auth";
import { helperMixin } from "@/mixins";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";
export default defineComponent({
    mixins: [helperMixin],
    components: {
        BaseFormApp,
        BaseFormGroup,
        CompanyLogo,
        TeleportToContentFooter,
        BaseFileInput,
    },
    data() {
        var _a, _b, _c, _d, _e, _f;
        const auth = authStore();
        return {
            validationErrors: {},
            form: {
                email: (_a = auth.state.user.company) === null || _a === void 0 ? void 0 : _a.email,
                name: (_b = auth.state.user.company) === null || _b === void 0 ? void 0 : _b.name,
                siupal_or_siuppak: (_c = auth.state.user.company) === null || _c === void 0 ? void 0 : _c.siupal_or_siuppak,
                address: (_d = auth.state.user.company) === null || _d === void 0 ? void 0 : _d.address,
                phone_number: (_e = auth.state.user.company) === null || _e === void 0 ? void 0 : _e.phone_number,
                website: (_f = auth.state.user.company) === null || _f === void 0 ? void 0 : _f.website,
            },
        };
    },
    computed: {
        authStore,
        authHttp,
    },
    methods: {
        async submit() {
            var _a;
            this.validationErrors = {};
            const { response } = (_a = (await this.requestHandler(this.authHttp.updateCompany(this.form)))) !== null && _a !== void 0 ? _a : { response: null };
            if (response) {
                this.authStore.state.user.company = response.data;
            }
            this.validationErrors = this.authHttp.validationErrors;
        },
    },
});
